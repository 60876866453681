:root {
  /* --c- prefix: c for custom */
  /* These spaces are temporary until we know which volvo spaces to use instead. */
  --c-space-12: 0.75rem;
  --c-space-20: 1.25rem;
  --c-space-40: 2.5rem;
  --c-space-56: 3.5rem;
  --c-space-80: 5rem;
  --c-space-96: 6rem;
  --c-space-128: 8rem;
  --c-max-content-width-12-columns: 1232px;
  --c-text-block-max-width: 704px;
  --c-content-max-width: 756px;
  --c-simple-page-layout-max-width: 1024px;
  --c-side-margin: 24px;
  --c-info-banner-height: 46px;
  --c-page-layout-grid: minmax(0, 1fr) minmax(200px, var(--c-menu-width));
  --c-header-height-until-l: 64px;
  --c-header-height-froml: 80px;
  --c-transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
  --c-text-color-transition: color 150ms ease;
  --c-scrollbar-width-thin: 8px;
  --c-color-scrollbar-dark-grey-track: var(--v-color-ornament-primary);
  --c-color-scrollbar-dark-grey-thumb: var(--v-color-foreground-secondary);
  --c-color-scrollbar-grey-track: var(--v-color-background-secondary);
  --c-color-scrollbar-grey-thumb: var(--v-color-ornament-primary);
  --c-color-tap-highlight: rgb(20 20 20 / 10%);
  --c-color-fade-background: linear-gradient(
    to bottom,
    rgb(255 255 255 / 100%) 0%,
    rgb(255 255 255 / 80%) 30%,
    rgb(255 255 255 / 0%) 100%
  );
}

[data-color-mode='dark'] {
  --c-color-tap-highlight: rgb(112 112 112 / 20%);
  --c-color-fade-background: linear-gradient(
    to bottom,
    rgb(0 0 0 / 100%) 0%,
    rgb(0 0 0 / 80%) 30%,
    rgb(0 0 0 / 0%) 100%
  );
}

.oip-text-max-width {
  max-width: var(--c-text-block-max-width);
}
